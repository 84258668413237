























































































import './scss/BaseFileInput.scss';
import './scss/BaseFileInputAdaptive.scss';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseFileInput',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class BaseFileInput extends Vue {
    $refs!: { fileInputElement: any }

    @Prop({ required: true }) filesInput!: string;
    @Prop({ default: '' }) label!: string;
    @Prop({ default: '' }) caption!: string;
    @Prop({ default: 'file' }) inputName!: string;
    @Prop({ default: true }) multiple!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) error!: boolean;
    @Prop({ default: false }) fileLink!: string;

    emitFilesChange() {
        this.$emit('files-change', this.files);
    }

    files: File[] = [];

    @Watch('files')
    watchFiles() {
        this.emitFilesChange();
    }

    changeFiles(files: FileList) {
        if (this.multiple) this.addNewFiles(files);
        else this.replaceFile(files);
        this.resetFileInputElement();
    }

    addNewFiles(files: FileList) {
        for (const file of files) {
            const isFileAdded = this.files.includes(file);
            if (isFileAdded) continue;

            this.files.push(file);
        }
    }

    replaceFile(files: FileList) {
        this.files.splice(0, 1, files[0]);
    }

    deleteFile(fileIndex: number) {
        this.files.splice(fileIndex, 1);
        this.resetFileInputElement();
    }

    resetFiles() {
        this.files = [];
        this.resetFileInputElement()
    }

    resetFileInputElement() {
        this.$refs.fileInputElement.value = '';
    }
}
